/* Forgot Password */

.forgot-password-flex{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
}

.forgot-password-container {
    width: 433px;
    height: 398px;
    background-color: #E9ECFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}

.forgot-password-div {
    width: 357px;
    height: 306px;
}

.forgot-password-title {
    font-size: 24px;
    font-weight: 500;
    color: #0C1421;
}

.forgot-password-text {
    font-size: 14px;
    color: #717171;
}

.forgot-password-pass input {
    width: 357px;
    height: 48px;
    border: 1px solid #C7C7C7;
    border-radius: 4px;
    padding-left: 16px;
}

.forgot-password-pass,
.forgot-password-confirm-pass{
    position: relative;
}

.forgot-password-confirm-pass input {
    width: 357px;
    height: 48px;
    border: 1px solid #C7C7C7;
    border-radius: 4px;
    padding-left: 16px;
}

.forgot-password-btn button {
    width: 357px;
    height: 50px;
    border-radius: 4px;
    background-color: #3F53D9;
    color: #fff;
}

@media (max-width: 700px) {

    .forgot-password-container,
    .forgot-password-div {
        width: 94%;
        background-color: #fff;
    }

    .forgot-password-confirm-pass input {
        width: 100%;
    }

    .forgot-password-pass input {
        width: 100%;
    }

    .forgot-password-btn button {
        width: 100%;
    }

    .forgot-password-flex{
        align-items: flex-start;
    }
}