.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-error-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader img {
  height: 200px;
}

.logout-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}