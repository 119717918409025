/* Verify Email */

.verify-email-flex{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
}

.verify-email-container {
    background-color: #E9ECFF;
    width: 433px;
    height: 302px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.verify-email-div {
    width: 358px;
    height: 213px;
}

.verify-email-title {
    font-size: 24px;
    color: #0C1421;
    font-weight: 300;
}

.verify-email-text {
    font-size: 14px;
    color: #717171;
}

.verify-email input {
    border: 1px solid #C7C7C7;
    border-radius: 4px;
    width: 357px;
    height: 48px;
    padding-left: 16px;
}

.verify-email-btn button {
    background-color: #3F53D9;
    color: #fff;
    width: 357px;
    height: 50px;
    border-radius: 4px;
    margin-top: 15px;
}

@media (max-width: 700px) {

    .verify-email-flex{
        align-items: flex-start;
    }

    .verify-email-container,
    .verify-email-div {
        width: 94%;
        background-color: #fff;
    }

    .verify-email input,
    .verify-email-btn button {
        width: 98%;
    }
}