/* Success PopUp Style */
.success_popup-container {
    width: 433px;
    height: 337px;
    border-radius: 8px;
    background-color: #E9ECFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.success_popup-div {
    width: 268px;
    height: 235px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.success_popup-icon img {
    height: 45px;
    width: 45px;
}

.success_popup-title {
    color: #050038;
    font-weight: 500;
    font-size: 24px;
}

.success_popup-text {
    font-size: 16px;
    color: #717171;
}

.success_popup-btn button {
    width: 212px;
    height: 50px;
    background-color: #3F53D9;
    color: #fff;
    border-radius: 4px;
}

/* Success PopUp Media Queries */
@media (max-width: 600px) {

    .success_popup-div {
        width: 80%;
    }

    .success_popup-container {
        height: 237px;
        width: 300px;
    }

    .success_popup-div {
        height: 135px;
    }


    .success_popup-icon img {
        height: 25px;
        width: 25px;
    }

    .success_popup-title {
        font-size: 14px;
    }

    .success_popup-text {
        font-size: 10px;
    }

    .success_popup-btn button {
        width: 100px;
        height: 30px;
        font-size: 10px;
    }
}