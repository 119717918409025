* {
    box-sizing: border-box;
    margin: 0;
}

:root {
    --darkBlue: #050038;
    --white: #ffffff;
    --headerSocial: #c5c3e5;
    --anchorColor: #3f53d9;
    --lightBlueBg: #e9ecff;
    --borderColor: #c7c7c7;
    --grey: #848181;
    --lightTextColor: #717171;
    --red: red;
}

body {
    background-image: url("../../assets/images/bgimage.svg");
    background-repeat: no-repeat;
    background-position: center bottom;
    height: 91vh;
}

h1,
h2,
h3 {
    color: var(--darkBlue);
}

.Demo,
.Demo>button,
.submit-btn {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: var(--grey);
    line-height: 1.4;
    background: var(--bodyBg);
}

#submit-icon {
    opacity: 0.25;
}

header {
    background-color: var(--darkBlue);
    color: var(--white);
    position: sticky;
    top: 0;
    z-index: 2;
}

header .header-inner {
    max-width: 1320px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 12px;
}

header .header-inner .header-left {
    height: 45px;
}

header .header-inner .header-left img {
    width: 120px;
    height: auto;
}

header .header-inner .header-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
}

header .header-inner .header-right>div a {
    color: var(--headerSocial);
    text-decoration: none;
}

header .header-inner .header-right>div a:hover {
    text-decoration: underline;
}

header .header-inner .header-right>div img {
    position: relative;
    margin-right: 5px;
}

/* header ends */
/* body sections */
section {
    max-width: 1220px;
    margin: 0 auto;
    padding: 0 12px;
}

section.top-liner {
    margin-top: 40px;
}

section.top-liner h1 {
    font-size: 32px;
    font-weight: 400;
    line-height: 38px;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 5px;
}

section.top-liner h1>span {
    color: var(--anchorColor);
}

section.top-liner .text-slide h1 p {
    margin-left: 5px;
}

section.top-liner h1 .text-slide span {
    position: relative;
    display: inline;
    font-size: 0;
    transition: all 0.3s ease;
    animation: slide 10s step-start infinite;
}

section.top-liner h1 .text-slide span::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border-left: 3px solid black;
    background-color: #ffffff;
    animation: typing 5s infinite, cursor 1s infinite;
}

section.top-liner h1 .text-slide span:nth-child(1) {
    animation-delay: 0s;
}

section.top-liner h1 .text-slide span:nth-child(1)::after {
    animation-delay: 0s;
    animation-timing-function: steps(46), step-end;
}

section.top-liner h1 .text-slide span:nth-child(2) {
    animation-delay: 5s;
}

section.top-liner h1 .text-slide span:nth-child(2)::after {
    animation-delay: 5s;
    animation-timing-function: steps(43), step-end;
}

@keyframes cursor {

    from,
    to {
        border-color: transparent;
    }

    50% {
        border-color: black;
    }
}

@keyframes typing {
    from {
        width: 100%;
    }

    90%,
    to {
        width: 0;
    }
}

@keyframes slide {
    50% {
        font-size: 32px;
        font-weight: 400;
        line-height: 38px;
    }

    to {
        font-size: 0;
        letter-spacing: 0;
    }
}

section.top-section {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

section.top-section h2 {
    font-size: 30px;
    font-weight: 300;
    line-height: 40px;
}

.back-button {
    align-self: flex-start;
}

.back-button a,
.back-button span {
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    color: var(--darkBlue);
    background: url('../../assets/images/back-arrow.svg') no-repeat;
    padding-left: 25px;
    background-size: 10px;
}

.select {
    width: 380px;
    height: 44px;
    color: black;
}

section.top-section .select-options>select {
    border: 1px solid var(--borderColor);
    border-radius: 5px;
    width: 380px;
    height: 44px;
    padding-left: 15px;
    padding-right: 50px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: window;
    background-image: url("../../assets/images/drop-icon.svg");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 11px;
    font-size: 16px;
    font-weight: 400;
}

section.top-section .select-options {
    position: relative;
}

section.top-section .select-options .select-error {
    color: var(--red);
    font-size: 12px;
    position: absolute;
    bottom: -20px;
    letter-spacing: 0.2px;
}

section.top-section .select-options>select:focus {
    outline: none;
}

/* slider section */

.slide-item {
    color: rgb(5, 0, 56);
    background-color: rgb(233, 236, 255);
    border-radius: 9px;
    margin: 18px;
}

.slide-item:hover {
    cursor: pointer;
    color: white;
    background-color: #3f53d9;
    transition: all 0.5s ease;
}

section.slider-wrap {
    margin-top: 20px;
    padding: 0;
    position: relative;
}

section.slider-wrap .react-multi-carousel-list {
    position: static;
    margin: 0 -6px;
}

.slide-item .slider-box {
    width: 100%;
    height: 170px;
    padding: 15px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    position: relative;
    margin: 0 auto;
}

.slide-item .slider-box .slider-text {
    display: block;
}

.slider-circle-icon {
    background-color: var(--white);
    width: 36px;
    height: 36px;
    border-radius: 100%;
    position: absolute;
    right: 15px;
    bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slider-wrap .react-multiple-carousel__arrow {
    background-color: var(--white);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.27);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.27);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.27);
    border-radius: 100%;
    min-width: 30px;
    min-height: 30px;
    background-color: var(--white);
    z-index: 1;
    padding: 0;
}

.slider-wrap .react-multiple-carousel__arrow:hover {
    background-color: var(--white);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.27);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.27);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.27);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slider-wrap .react-multiple-carousel__arrow--left {
    left: 5px !important;
}

.slider-wrap .react-multiple-carousel__arrow--right {
    right: 5px !important;
}

.slider-wrap .react-multiple-carousel__arrow--left::before,
.slider-wrap .react-multiple-carousel__arrow--right::before {
    color: var(--darkBlue);
    left: 0;
    position: absolute;
    top: 8px;
    right: 0;
    margin: 0 auto;
    font-weight: bold;
    font-size: 15px;
    line-height: 14px;
}

.slider-wrap .react-multiple-carousel__arrow--right::before {
    right: -4px;
}


/* query section */
section.query-section {
    margin-top: 25px;
    position: fixed;
    width: 100%;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 50px;
    z-index: 1;
}

section.query-section .query-input-style {
    border: 1px solid var(--borderColor);
    font-size: 16px;
    font-weight: 400;
    color: var(--darkBlue);
    height: 66px;
    width: 100%;
    border-radius: 10px;
    padding-right: 65px;
    padding-left: 25px;
    transition: all 0.3s ease;
}

section.query-section .query-input-style:focus {
    outline: none;
    border-color: var(--grey);
}

section.query-section .submit-btn {
    border: 0;
    padding: 0;
    margin: 0;
    position: absolute;
    right: 30px;
    top: 17px;
    cursor: pointer;
    opacity: 1;
    transition: all 0.3s ease;
}

section.query-section .submit-btn:disabled {
    opacity: 0.6;
}

/* inner page */
.ques-ans-block {
    padding: 10px;
    font-size: 16px;
    line-height: 24px;
    height: 72vh;
    overflow: auto;
}

.ques-ans-block::-webkit-scrollbar {
    display: none;
}

.ques-ans-block .question-block,
.ques-ans-block .answer-block {
    position: relative;
    padding-left: 70px;
    margin-bottom: 60px;
    color: var(--darkBlue);
}

.ques-ans-block .answer-block {
    margin-bottom: 0;
}

.ques-ans-block .answer-block>ul {
    margin: 15px 0 0 20px;
}

.ques-ans-block .answer-block>ul>li {
    list-style: disc;
    margin-bottom: 10px;
}

.ques-ans-block .question-block:before,
.ques-ans-block .answer-block:before {
    content: "";
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-color: var(--lightBlueBg);
    position: absolute;
    left: 0;
    top: -10px;
}

.ques-ans-block .answer-block:before {
    background-color: var(--anchorColor);
}

.ques-ans-block .question-block:after,
.ques-ans-block .answer-block:after {
    content: "";
    width: 50px;
    height: 50px;
    position: absolute;
    left: 0;
    top: -10px;
    background: url("../../assets/images/user-icon.svg") no-repeat;
    background-position: center;
}

.ques-ans-block .answer-block:after {
    background: url("../../assets/images/ans-icon.svg") no-repeat;
    background-position: center;
}

/* like dislike button */
.like-dislike-button-wrap {
    margin-top: 25px;
    display: flex;
    justify-content: flex-start;
    gap: 30px;
}

.like-dislike-button-wrap>div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 55px;
    gap: 7px;
    color: var(--darkBlue);
    font-size: 16px;
}

.like-dislike-button-wrap>div>button {
    border: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

/* end body sections */
/* footer strat */
footer {
    background-color: var(--darkBlue);
    color: var(--white);
    padding: 20px 0;
    margin-top: 75px;
    position: fixed;
    bottom: 0;
    width: 100%;
}

footer .footer-inner {
    max-width: 1320px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 14px;
    padding: 12px;
}

footer .footer-inner .footer-links>a {
    margin-right: 30px;
    color: var(--white);
    text-decoration: none;
}

footer .footer-inner .footer-links>a:hover {
    text-decoration: underline;
}

footer .footer-inner .footer-links>a:last-child {
    margin-right: 0;
}

.skeleton-container {
    display: flex;
    align-items: center;
    border-radius: 8px;
    width: 100%;
}

.skeleton-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #e0e0e0;
    margin-right: 16px;
    animation: pulse 0.8s infinite ease-in-out;
}

.skeleton-text {
    flex: 1;
}

.skeleton-line {
    height: 16px;
    background-color: #e0e0e0;
    border-radius: 4px;
    animation: pulse 0.8s infinite ease-in-out;
    margin-bottom: 8px;
}

.skeleton-line:last-child {
    width: 60%;
    margin-bottom: 0;
}

@keyframes pulse {
    0% {
        opacity: 0.6;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.6;
    }
}

/* footer end */
/* media starts here */
@media only screen and (max-width: 1200px) {
    section.slider-wrap {
        margin-left: 10px;
        margin-right: 10px;
    }

    @keyframes slide {
        50% {
            font-size: 26px;
            font-weight: 400;
            line-height: 32px;
        }

        to {
            font-size: 0;
            letter-spacing: 0;
        }
    }
}

@media only screen and (max-width: 1023px) {
    section.top-section {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        margin-top: 25px;
    }

    section.top-section .select-options {
        width: 100%;
    }

    section.top-section .select-options>select {
        width: 100%;
    }

    section.slider-wrap {
        padding-left: 12px;
        padding-right: 12px;
    }
}

@media only screen and (max-width: 992px) {

    header .header-inner,
    section,
    footer .footer-inner {
        padding-left: 20px;
        padding-right: 20px;
    }

    section.top-liner {
        margin-top: 20px;
    }

    section.top-liner h1 {
        font-size: 28px;
        flex-direction: column;
    }

    section.top-section h2 {
        font-size: 24px;
        line-height: 30px;
    }
}

@media only screen and (max-width: 767px) {
    body {
        background-position: left -170px bottom;
        background-size: 1280px;
    }
}

@media only screen and (max-width: 600px) {
    .skeleton-icon{
        height: 40px;
        width: 40px;
    }

    header .header-inner .header-left {
        height: 40px;
    }

    header .header-inner .header-left img {
        width: 105px;
        height: 40px;
    }

    header .header-inner .header-right .header-right-div {
        display: none;
    }

    .dropdown {
        padding-right: 5px;
    }

    section.top-liner h1 {
        line-height: 24px;
    }

    .ques-ans-block {
        font-size: 14px;
        line-height: 21px;
    }

    .ques-ans-block .question-block {
        margin-bottom: 26px;
    }

    .ques-ans-block .question-block,
    .ques-ans-block .answer-block {
        padding-left: 55px;
    }

    .ques-ans-block .question-block:before,
    .ques-ans-block .answer-block:before,
    .ques-ans-block .question-block:after,
    .ques-ans-block .answer-block:after {
        width: 40px;
        height: 40px;
        top: 0;
    }

    .ques-ans-block .question-block:after {
        background-size: 15px;
    }

    .ques-ans-block .answer-block:after {
        background-size: 20px;
    }

    .ques-ans-block .answer-block>ul {
        margin-left: 15px;
    }

    section.query-section .query-input-style {
        height: 50px;
        font-size: 14px;
        padding-left: 15px;
    }

    section.query-section .submit-btn {
        top: 10px;
    }

    section.query-section .submit-btn img {
        width: 24px;
    }

    section.query-section {
        margin-top: 10px;
    }

    footer {
        margin-top: 30px;
        padding: 5px 0;
    }

    footer .footer-inner {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 7px;
    }

    @keyframes slide {
        50% {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.3px;
        }

        to {
            font-size: 0;
            letter-spacing: 0;
        }
    }
}

@media only screen and (max-height: 700px) {
    .ques-ans-block {
        height: 68vh;
        padding-bottom: 30px;
    }
}

.answer-block h1 {
    font-size: 32px;
    font-weight: bold;
}

.answer-block h2 {
    font-size: 24px;
    font-weight: bold;
}

.answer-block h3 {
    font-size: 18px;
    font-weight: bold;
}

.answer-block h4 {
    font-size: 16px;
    font-weight: bold;
}