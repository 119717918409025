*{
    font-family: "Roboto", sans-serif;
}

.login-form-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
}

.login-form {
    height: 417px;
    width: 433px;
    border-radius: 8px;
    background-color: #E9ECFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-form-inner {
    width: 358px;
    padding-top: 10px;
}

.login-text-primary {
    font-size: 24px;
    color: #0C1421;
}

.login-text-secondary {
    font-size: 14px;
    color: #717171;
    margin-top: -10px;
}

.login-input-box input {
    width: 357px;
    height: 48px;
    border: 1px solid #C7C7C7;
}

.login-terms {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}

.login-terms-check{
    display: flex;
    align-items: flex-start;
    gap: 10px;
}

.login-terms-check-text-1 {
    font-size: 11.5px;
    color: #717171;
    margin-bottom: 4px;
}

.login-terms-check-text-2{
    color: #717171;
    font-size: 11.5px;
}

.login-terms-check-text-special {
    color: #3F53D9;
    text-decoration: none;
}

.login-btn {
    border: none;
    width: 357px;
    height: 50px;
    border-radius: 4px;
}

.login-terms-check input {
    height: 15px;
    width: 15px;
    border: 1px solid #C7C7C7;
}

.error-msg-div {
    display: flex;
    align-items: center;
    justify-content: center;
    color: red;
    font-size: 14px;
    margin-bottom: 0px;
}

.remember-me{
    color: #717171;
    font-size: 14px;
}

.forgot-password{
    font-size: 14px;
    color: #3F53D9;
    cursor: pointer;
}


@media (max-width: 600px) {
    .login-form {
        width: 98%;
        background-color: #fff;
    }

    .login-form-container {
        align-items: baseline;
    }

    .login-terms-check-text-1 {
        font-size: 11px;
    }

    .login-form-inner{
        width: min-content;
    }

    .login-btn {
        width: 300px;
    }

    .login-input-box input{
        width: 300px;
    }
}